import React from "react";
import resumeFile from "../documents/resume.pdf";
import Reveal from "react-awesome-reveal";
import {fadeInUpShorter} from "../utils/keyframes";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About We
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            뮤라클을 소개합니다.
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              We're <span className="text-primary">Muracle,</span> Web/App Agency
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
              <span className="text-white fw-semibold bg-primary p-1">전문성</span><br/>
              뮤라클은 웹페이지 개발과 앱 개발(안드로이드, iOS)을 전문으로 하는 IT 소프트 개발 업체입니다.
              직원들은 전문성과 경력이 뛰어난 전문가들로 구성되어 있으며, 15년에서 16년 이상의 경력을 보유하고 있습니다.
              또한, 단순한 웹 사이트뿐만 아니라 장기적인 큰 프로젝트들도 수행한 경험이 풍부합니다.
              이러한 경험과 노하우를 바탕으로, 고객의 요구사항을 정확하게 파악하고 높은 수준의 개발을 제공합니다.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              <span className="text-white fw-semibold bg-primary p-1">고객 가치 제공</span><br/>
              뮤라클은 고객이 지불한 금액 이상의 가치를 제공하는 것이 중요하다고 생각합니다.
              고객의 목표를 달성하기 위해 함께 고민하고, 최선을 다해 개발을 진행합니다.
              뮤라클은 기획서에 명시된 요구사항을 그대로 개발하는 것이 아니라, 개발자와 기획자가 함께 UI/UX를 꼼꼼히 검토하고,
              사용자의 경험을 고려하여 검수 과정을 거칩니다.
              또한, 개발 중 예상되는 문제점을 미리 파악하여 고객과 함께 공유하고 수정할 수 있도록 노력합니다.
              뮤라클은 문제를 빠르게 파악하고 해결함으로써 고객의 시간과 비용을 아낄 수 있으며, 고객에게 더 많은 가치를 제공할 수 있습니다.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              <span className="text-white fw-semibold bg-primary p-1">체계적인 개발</span><br/>
              뮤라클에서는 앱 개발 전, 사이트 분석을 통해 필요한 기능과 페이지들을 명세화합니다.
              이를 토대로 중요한 내용을 리스트에 정리하여 개발 중 빠뜨리는 일 없이 체계적으로 개발을 진행합니다.
              이 리스트를 작성함으로써 필요한 기능이 확정되고, 견적을 산출할 수 있게 됩니다.<br/><br/>

              또한 뮤라클은 개발 완료일에만 사이트나 앱을 보여드리지 않습니다.
              개발 진척률을 공유하는 엑셀 파일과 테스트 리스트를 개발할 때부터 공유해드리고 있습니다.
              이를 통해 고객과 개발자가 함께 개발 진행 상황을 파악하고, 필요한 수정 사항을 적극적으로 반영할 수 있습니다.<br/><br/>

              개발이 완료되는 부분이 있을 경우, 뮤라클은 바로 보여드리고 수정 작업을 진행합니다.
              이를 통해 고객은 개발 과정에서 불필요한 수정 작업에 시간과 비용을 소비하지 않을 수 있습니다.
              뮤라클의 체계적인 개발 방식은 고객의 만족도를 높이는 데 큰 역할을 합니다.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              <span className="text-white fw-semibold bg-primary p-1">끊임없는 기술 개발과 혁신</span><br/>
              뮤라클은 항상 최신 기술 동향과 개발 방법론을 주시하고 적용하며, 끊임없이 기술력을 향상시키는 노력을 하고 있습니다.
              이를 위해 다양한 기술 세미나와 컨퍼런스에 참여하고, 기술과 개발 방법론에 대한 연구와 개발을 진행합니다.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              <span className="text-white fw-semibold bg-primary p-1">지속적인 유지보수와 관리</span><br/>
              뮤라클은 개발 완료 후에도 고객의 요구사항에 대한 지속적인 관리와 유지보수를 제공합니다.
              이를 위해 고객과의 소통을 통해 추가적인 개발 작업을 진행하고, 시스템 상의 문제를 해결합니다.
              이를 통해 고객의 만족도를 높이고, 오랜 기간에 걸쳐 안정적인 운영을 유지할 수 있습니다.<br/><br/>

              고객들은 뮤라클의 전문성과 고객 가치 제공, 체계적인 개발, 지속적인 유지보수와 관리에 대해 매우 만족하고 있습니다.
              이에 더하여 뮤라클은 합리적인 가격으로 고객에게 제공되며, 프로젝트의 크기나 복잡도에 상관없이 전문적인 개발 서비스를 제공합니다.
            </p>
            {/*<p className={darkTheme ? "text-white-50" : ""}>*/}
            {/*  저희는 고객이 지불한 금액 이상의 가치를 제공하는 것이 매우 중요하다고 생각합니다.*/}
            {/*  그래서 뮤라클에서는 고객의 목표를 달성하기 위해 함께 고민하며, 최선을 다해 개발을 진행합니다.*/}
            {/*  또한, 기획자와 개발자가 함께 UI/UX를 꼼꼼히 검토하여 사용자 경험을 최적화하고,*/}
            {/*  <span className="text-primary"> 검수 과정</span>을 거쳐 최종 결과물을 완성합니다.*/}
            {/*  예상되는 문제점도 미리 파악하여 고객과 함께 수정할 수 있도록 노력합니다.*/}
            {/*  이렇게 문제를 빠르게 파악하고 해결하면 개발 시간과 비용을 아낄 수 있습니다.*/}
            {/*</p>*/}
            {/*<p className={darkTheme ? "text-white-50" : ""}>*/}
            {/*  뮤라클의 직원들은 전문성과 경력이 뛰어난 전문가들입니다. 개발자들은 15년에서 16년 이상의 경력을 보유하며,*/}
            {/*  단순한 웹 사이트뿐만 아니라 장기적인 큰 프로젝트들도 수행한 경험이 풍부합니다.*/}
            {/*  이러한 경험과 노하우를 바탕으로, 고객의 요구사항을 정확하게 파악하고 높은 수준의 개발을 제공합니다.*/}
            {/*</p>*/}
            {/*<p className={darkTheme ? "text-white-50" : ""}>*/}
            {/*  /!* 외주를 맡길 때 가장 답답한 점이 무엇일까요? 개발은 잘 되고 있는지 확인할 방법이 마땅치 않다는 점입니다.*!/*/}
            {/*  뮤라클에서는 앱 개발 전, <span className="text-primary">사이트 분석</span>을 통해 필요한 기능과 페이지를 명세화하고,*/}
            {/*  중요한 내용을 리스트에 정리하여 체계적으로 개발을 진행합니다.*/}
            {/*  이 리스트를 작성함으로써 필요한 기능이 확정되고, 견적을 산출할 수 있습니다.*/}
            {/*</p>*/}
            {/*<p className={darkTheme ? "text-white-50" : ""}>*/}
            {/*  저희는 개발 완료일에만 사이트나 앱을 보여드리지 않습니다.*/}
            {/*  <span className="text-primary"> 개발 진척률을 공유하는 엑셀 파일</span>과 테스트 리스트를 개발할 때부터 고객과 공유하며,*/}
            {/*  개발 중 수정이 필요한 부분이 있으면 바로 보여드리고 수정 작업을 진행합니다.*/}
            {/*  실제로 사이트나 앱은 직접 만져보지 않으면 잘 모르는 부분이 많습니다. 그래서 실시간으로 수정 작업을 진행하여 빠르고 정확한 결과물을 얻을 수 있습니다.*/}
            {/*  완성하기 전에 수정하면 시간과 비용을 절약할 수 있으며, 최종 완성물도 보다 완벽한 수준으로 제공할 수 있습니다.*/}
            {/*</p>*/}
            {/*<p className={darkTheme ? "text-white-50" : ""}>*/}
            {/*  저희는 각 페이지의 기능과 체크해야 할 상황들, 에러가 발생할 가능성이 있는 상황들을 모두 포함한 <span className="text-primary">테스트 리스트</span>를 작성합니다.*/}
            {/*  이 리스트는 개발 중 참고하여 해당 기능을 개발하고 나면 테스트를 거쳐 정상 작동하는지 확인합니다.*/}
            {/*  더불어, 이 리스트는 고객분들께 공유되어 실제로 작동되는 부분이 어떻게 작동하는지를 확인하고 리스트에 내용을 추가, 수정할 수 있습니다.*/}
            {/*  최종 결과물은 테스트 리스트가 모두 통과된 후에만 납품하며, 고객분들도 해당 리스트를 검증하여 사이트가 올바르게 개발되었는지 확인할 수 있습니다."*/}
            {/*</p>*/}
            {/*<p className={darkTheme ? "text-white-50" : ""}>*/}
            {/*뮤라클은 프로젝트를 시작하기 전, 고객의 요구사항을 정확히 파악하고 이를 기반으로 <span className="text-primary">철저한 기획 단계</span>를 거치고 있습니다.*/}
            {/*  이를 통해 고객의 목표와 요구사항을 정확히 이해하고, 더 나은 결과물을 제공할 수 있습니다.*/}
            {/*  </p>*/}
            {/*<p className={darkTheme ? "text-white-50" : ""}>*/}
            {/*  뮤라클은 <span className="text-primary">고객의 만족도</span>를 최우선으로 생각하고 있습니다.*/}
            {/*  개발 완료 후에도 고객의 요구사항에 대한 문의나 수정사항에 대해 신속하고 정확하게 대응하며,*/}
            {/*  최종 완성물이 고객의 요구사항을 충족시키도록 노력합니다.*/}
            {/*</p>*/}
            {/*<p className={darkTheme ? "text-white-50" : ""}>*/}
            {/*  뮤라클은 프로젝트 완료 후에도 <span className="text-primary">고객과의 소통</span>을 계속 유지합니다.*/}
            {/*  고객이 직면할 수 있는 문제나 개선사항이 있을 경우, 빠른 대응을 통해 고객의 만족도를 높이는 것을 목표로 합니다.*/}
            {/*</p>*/}
            {/*<p className={darkTheme ? "text-white-50" : ""}>*/}
            {/*뮤라클은 최신 기술 동향과 개발 방법론을 항상 주시하고 적용하며, 끊임없이 기술력을 향상시키는 노력을 하고 있습니다.*/}
            {/*  이를 통해 고객에게 항상 <span className="text-primary">최신 기술과 높은 수준의 개발 서비스</span>를 제공할 수 있습니다.*/}
            {/*</p>*/}

            {/*뮤라클은 고객의 요구사항에 맞춰 최적의 솔루션을 제공합니다. 개발 전부터 고객과의 논의를 통해 목표를 분명하게 정하고, 개발 과정에서도 지속적으로 의사소통을 합니다. 이를 통해 고객이 원하는 결과물을 정확하게 제공하며, 만족도를 높입니다.*/}

            {/*뮤라클에서는 개발 완료 후에도 열심히 관리합니다. 개발 완료 후에는 고객에게 필요한 교육과 지원을 제공하며, 유지보수와 업그레이드까지도 책임지고 있습니다.*/}

            {/*뮤라클은 비즈니스적인 측면도 중요하게 생각합니다. 고객의 목표와 비즈니스적인 목표를 함께 고민하고, 그에 맞게 최적의 솔루션을 제공합니다. 이를 통해 고객이 만족하는 것은 물론, 비즈니스적인 성과를 이룰 수 있도록 노력합니다.*/}

            {/*뮤라클은 기술력과 전문성을 바탕으로 고객에게 최상의 서비스를 제공합니다. 향후에도 지속적으로 기술력과 노하우를 향상시켜서, 더 나은 서비스를 제공할 예정입니다.*/}
            {/*또한, 뮤라클은 개발 완료 이후에도 충분한 지원을 제공합니다. 앱이나 웹사이트를 제작하는 것만이 끝이 아니라, 유지보수와 업그레이드가 필요합니다. 이를 위해 뮤라클은 고객에게 시스템 유지보수와 업그레이드 서비스를 제공하고 있습니다. 이는 소프트웨어의 신뢰성과 보안성을 높이는데 큰 역할을 합니다.*/}

            {/*마지막으로, 뮤라클은 고객들과의 소통과 커뮤니케이션을 매우 중요하게 생각합니다. 고객들과의 회의나 토의를 통해 요구사항을 정확히 파악하고, 고객의 목표를 달성하기 위해 적극적으로 협력합니다. 또한, 개발 중에는 고객들에게 실시간으로 진행 상황을 공유하고, 고객의 피드백을 반영하여 개발 과정을 보완합니다. 이러한 고객 중심적인 서비스를 제공하여, 뮤라클은 고객들로부터 높은 평가와 신뢰를 받고 있습니다.*/}
            {/*뮤라클에서는 개발 완료 후에도 고객과의 소통을 지속합니다. 개발 완료 후에도 유지보수 및 업데이트를 지속적으로 제공하며, 추가 기능을 요청하거나 문제점을 보고할 때도 빠르게 대처합니다. 이러한 고객 중심적인 접근 방식은 고객 만족도를 높이고, 장기적인 관계를 유지하는데 큰 역할을 합니다.*/}

            {/*마지막으로, 뮤라클은 기술 발전에 대한 끊임없는 관심을 가지고 있습니다. 새로운 기술 도입과 연구 개발을 통해 더 나은 서비스를 제공하기 위해 항상 노력하고 있습니다. 이러한 노력은 고객에게 더욱 효율적이고 혁신적인 솔루션을 제공하는데 큰 도움이 됩니다.*/}
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Muracle(뮤라클)</span>
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:chat@simone.com">contact@muracle.io</a>
                </li>
                <li>
                  <span className="fw-600 me-2">회사 설립일:</span>2019.09.01
                </li>
                {/*<li>*/}
                {/*  <span className="fw-600 me-2">인원:</span>5*/}
                {/*</li>*/}
                <li className="border-0">
                  <span className="fw-600 me-2">위치:</span>서울시 마포구 독막로28길 10,<br/>109동 상가 b101-803호<br/>(밤섬 경남아너스빌,신수동)
                </li>
              </ul>
              {/*<a*/}
              {/*  href={resumeFile}*/}
              {/*  download*/}
              {/*  className="btn btn-primary rounded-pill"*/}
              {/*>*/}
              {/*  Download CV*/}
              {/*</a>*/}
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <Reveal className="col-6 col-md-3" keyframes={ fadeInUpShorter } delay={ 100 } duration={ 500 } /*triggerOnce*/>
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>15</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experiance
                </p>
              </div>
            </Reveal>
            <Reveal className="col-6 col-md-3" keyframes={ fadeInUpShorter } delay={ 300 } duration={ 500 } /*triggerOnce*/>
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>30</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Clients
                </p>
              </div>
            </Reveal>
            <Reveal className="col-6 col-md-3" keyframes={ fadeInUpShorter } delay={ 500 } duration={ 500 } /*triggerOnce*/>
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>50</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </Reveal>
            <Reveal className="col-6 col-md-3" keyframes={ fadeInUpShorter } delay={ 700 } duration={ 500 } /*triggerOnce*/>
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>21</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Get Skills
                </p>
              </div>
            </Reveal>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
