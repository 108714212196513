import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    App: "App",
    Web: "Web",
    ECommerce: "ECommerce",
    Publishing: "Publishing",
    // DESIGN: "Desing",
    // BRAND: "Brand",
    // PHOTOS: "Photos",
  };

  const projectsData = [
    {
      title: "Coloso 플레이어 안드로이드, 아이폰 앱 개발",
      projectInfo:
        "Coloso의 JP, US 플레이어를 개발.\n" +
          "카테노이드의 비디오 스트리밍 SDK를 이용하여 플레이어를 개발함.\n\n" +
          "- 최소버전\n" +
          "iOS는 13.0,  Android는 마시멜로(6.0, SDK23).\n" +
          "\n" +
          "- 차수 별 개발 내역\n" +
          "1차(2021.09~2021.11) : 플레이어를 제외한(플레이어는 재생만 되게) Android, iOS앱 제작 : 로그인, 앱메뉴, 나의 강의 리스트, 강의 목차, 결제내역, 환불내역, 설정 기타 페이지를 제작\n" +
          "2차(2022.01~2022.03) : iOS 콜로소 플레이어 개발(iPhone, iPad), 애플 스토어에 런칭\n" +
          "3차(2022.03~2022.05) : Android 콜로소 플레이어 개발(Android Mobile, Pad), 구글 스토어에 런칭\n" +
          "\n" +
          "- 주요 기능:\n" +
          "로그인, 앱메뉴, 나의 강의 리스트, 강의 목차, 결제내역, 환불내역, 설정,\n" +
          "강의장(플레이어), 플레이어 전체화면, 플레이어 기능(재생, 정지, 화면 사이즈 변경, 북마크 저장, 배속, 음량조절, 음소거, 자막설정(크기, 색상, 배경), 자막 변경, 앞뒤 이동, 자동 이어보기 설정, 화면 회전, 화면 확대/축소 등)",
      client: "Coloso",
      technologies: "iOS, Swift, SwiftUI, Android, Kotlin",
      range: "네이티브 앱 개발(iOS, Android) 100%",
      date: "2021.09 ~ 2022.05",
      url: [{
        name: "구글 스토어(Android)",
        link: "https://play.google.com/store/apps/details?id=day1company.coloso.app",
      },
      {
        name: "애플 스토어(iOS)",
        link: "https://apps.apple.com/us/app/coloso/id1597698043?platform=ipad",
      }],
      socialLinks: {
      },
      thumbImage: "images/projects/coloso/mobile2.png",
      sliderImages: [
        "images/projects/coloso/mobile1.png",
        "images/projects/coloso/mobile3.png",
        "images/projects/coloso/mobile4.png",
      ],
      categories: [filters.App],
    },
    {
      title: "한인텔 사이트 리뉴얼(고객용, 파트너용, 관리자용 사이트 클라이언트 & 서버 개발)",
      projectInfo:
        "기존 한인텔 사이트(PHP, yii)의 Database만을 가지고 프론트엔드와 서버를 새롭게 개발하는 프로젝트.\n\n" +
          "- 작업 범위:\n" +
          "기존 사이트를 분석하고 정리, 데이터베이스 마이그레이션 처리\n" +
          "고객용, 파트너용, 관리자용 3개 웹사이트의 클라이언트 & 서버(Back-end) 개발\n" +
          "\n" +
          "- 주요 기능:\n" +
          "고객용 사이트 : 회원가입, 로그인, 메인페이지, 숙소 검색, 필터 검색, 숙소 상세보기, 예약, 결제, Q&A, 리뷰, 마이페이지, 포인트, 쿠폰, 프로필 관리, 내 여행 관리\n" +
          "파트너 사이트 : 파트너 등록, 지점 추가/수정, 파트너 숙소 등록, 숙소 관리, 스케쥴 관리, 달력 스케쥴, 지점상태, 가격관리, 예약관리, 리뷰관리, 결제 관리,  매출관리(EXCEL)\n" +
          "어드민 사이트 : 로그인, 회원가입, 전체 예약관리, 취소/환불 관리, 지점정보, 등급/커미션 관리, 마일리지 관리, 회원정보, 파트너 정보, 할인쿠폰, 쿠폰제휴, 계좌인증, 결제관리, 숙소 데이터 관리 등..\n" +
          "기존 db 마이그레이션",
      client: "한인텔",
      technologies: "Node.js, next.js, AWS, MySQL, React.js",
      range: "3개 사이트(고객용, 파트너용, 어드민용)의 프론트엔드(ReactJS) & 백엔드(NodeJS)",
      date: "2022.05 ~ 2022.11",
      url: [],thumbImage: "images/projects/hanintel/hanintel1.png",
      sliderImages: [
        "images/projects/hanintel/hanintel2.png",
        "images/projects/hanintel/hanintel3.png",
        "images/projects/hanintel/hanintel4.png",
        "images/projects/hanintel/hanintel5.png",
      ],
      categories: [filters.ECommerce, filters.Web],
    },
    {
      title: "해외시장 베이스의 이커머스 쇼핑몰 솔루션 & 관리자 개발",
      projectInfo:
        "해외 시장을 타겟으로한 모바일 퍼스트 쇼핑몰 솔루션\n" +
          "일반적인 쇼핑몰 한 개를 타겟팅으로 한 쇼핑몰이 아닌 \n" +
          "여러 쇼핑몰에 적용할 수 있도록 개발.\n\n" +
          "-주요 기능\n" +
          "쇼핑몰 - 메인/프로모션, 일반회원가입/소셜회원가입, 일반로그인/소셜로그인/로그아웃, 공지, faq, 검색, 마이페이지/주문내역/주문취소, 카테고리별 리스트, 상품/상세, 카트, 결제\n" +
          "\n" +
          "쇼핑몰 관리자 - 상품관리, 리뷰관리, 재고관리, 배너관리, 컨텐츠 관리, 회원 관리, 주문 관리, 배송요청, 배송관리, 반품관리, 카테고리관리, 브랜드 관리, 프로모션 관리, 공지관리, 스토어관리, 푸터설정",
      client: "",
      technologies: "PHP, laravel, Vue.js, Javascript",
      range: "고객용 쇼핑몰 프론트엔드 & 백엔드, 쇼핑몰 관리자 프론트엔드 & 백엔드",
      date: "2021.09 ~ 2022.11",
      url: [],thumbImage: "images/projects/global-shop/e1.png",
      sliderImages: [
        "images/projects/global-shop/e2.png",
        "images/projects/global-shop/e3.png",
        "images/projects/global-shop/e4.png",
        "images/projects/global-shop/e5.png",
        "images/projects/global-shop/e6.png",
        "images/projects/global-shop/e7.png",
        "images/projects/global-shop/e8.png",
        "images/projects/global-shop/e9.png",
        "images/projects/global-shop/e10.png",
        "images/projects/global-shop/e11.png",
        "images/projects/global-shop/e12.png",
      ],
      categories: [filters.ECommerce, filters.Web],
    },
    {
      title: "3D베이스 쇼핑몰(고객/딜러) & 관리자",
      projectInfo:
        "3d stl 파일을 업로드받아 캐드파일로 추출하는 기능을 하는 라이브러리 코드를 이용하여 관리자 및 SW이용자/딜러가 각자 필요한 업무를 할 수 있도록 웹 클라이언트와 관리자 페이지를 개발\n\n- 작업 범위 & 주요 범위:\n" +
          "러프한 아이디어만 있는 상태에서 기획과 디자인 고객용 프론트 엔드와 백엔드(로그인 권한에 따라 딜러일 경우 다르게 보여짐)\n" +
          "관리자용 프론트 엔드와 백엔드 개발\n" +
          "\n" +
          "고객용 쇼핑몰(반응형) - 로그인, 회원가입, 상품상세, 장바구니, 결제, 마이페이지(크레딧 내역, 담당 딜러), F&Q, Q&A, 3D SW 페이지(3D확인 기능, 다운로드(결제)), 주문관리, 배송내역, 견적서/인보이스 다운로드, 크레딧 충전(결제), 챗봇\n" +
          "\n" +
          "딜러용 쇼핑몰(반응형) - 고객용 쇼핑몰과 거의 동일, 담당회원 관리, 크레딧 관리/충전, F&Q(딜러용), Q&A(딜러용), 주문, 장바구니, 챗봇\n" +
          "\n" +
          "관리자 사이트 - 로그인, 임직원 계정 추가/수정, 직원관리(권한 설정, 활동내역, 차단설정), 기타설정(수수료 설정, 담당 이메일 설정, pg 결제금액 설정), 회원관리(회원내역, 구매내역, 상담내역, 승인상태 관리, 차단 기능), 회원추가/수정(직접 회원 생성/추가 기능, 메모 입력, 크레딧 추가/차감 기능, 담당 딜러 변경(딜러 리스트에서), sw 이용비용 개별 설정, 딜러관리(딜러 생성/수정/차단, 딜러당 상품 단가 설정, 딜러별 주문활동, 딜러별 Q&A 내역), 상품관리(상품 내역, 회원/딜러 가격), 주문관리(주문내역, 배송 내역, 결제 내역, 내역 엑셀 다운로드), 주문 생성(직접 주문 추가, 결제 링크 이메일 전송), 주문 취소, 견적서/인보이스 저장, sw이용관리, 게시판 관리(Q&A, F&Q)\n",
      client: "3D Base",
      technologies: "AWS, PHP, Laravel, Vue.js, Javascript",
      range: "기획, 서버 구축, 쇼핑몰 프론트엔드 & 백엔드, 관리자 사이트 프론트엔드 & 백엔드",
      date: "2020.03 ~ 2020.09",
      url: [], 
      thumbImage: "images/projects/3d-base/d1.png",
      sliderImages: [
        "images/projects/3d-base/d2.png",
        "images/projects/3d-base/d3.png",
      ],
      categories: [filters.ECommerce, filters.Web],
    },
    {
      title: "강의 판매 샘플 사이트 개발",
      projectInfo:
          "합리적인 가격으로 강의 판매 사이트를 제작하실 수 있도록 강의 판매 샘플 사이트를 개발하였습니다.\n" +
          "샘플사이트를 기준으로 디자인 변경, 기능 추가로 원하는 사이트를 빠르게 제작 가능합니다.\n\n" +
          "- 주요 기능 & 작업:\n" +
          "신규 강의, Best 인기강의 표시, 후기, 커뮤니티(공지사항, 자주하는 질문FAQ, 문의게시판), 강의 신청 페이지,\n" +
          "마이페이지(대시보드, 수강중인 과정, 결제내역 조회, 위시리스트, 회원정보 수정)\n" +
          "강의실(진도율 표시, 학습하기 버튼, 공지사항, 강의자료, Q&A, 수강후기)\n" +
          "장바구니, 결제",
      client: "",
      technologies: "ReactJs, 리덕스, NextJs, Apollo GraphQL, vercel, scsss, Node.js",
      range: "기획, 서버 구축, 사이트 프론트엔드 & 백엔드, 관리자 사이트 프론트엔드 & 백엔드",
      date: "2023.04 ~ 2023.04",
      url: [{
        name: "샘플사이트 고객용",
        link: "https://muracle-demo-lms.vercel.app/",
      }],
      thumbImage: "images/projects/muracle-demo-lms/lms1.png",
      sliderImages: [
        "images/projects/muracle-demo-lms/lms2.png",
        "images/projects/muracle-demo-lms/lms3.png",
        "images/projects/muracle-demo-lms/lms4.png",
        "images/projects/muracle-demo-lms/lms5.png",
        "images/projects/muracle-demo-lms/lms6.png",
        "images/projects/muracle-demo-lms/mobile1.png",
        "images/projects/muracle-demo-lms/mobile2.png",
      ],
      categories: [filters.ECommerce, filters.Web],
    },
    {
      title: "강의 판매 어드민 사이트 개발",
      projectInfo:
          "합리적인 가격으로 강의 판매 사이트를 제작하실 수 있도록 강의 판매 샘플 사이트를 개발하였습니다.\n" +
          "샘플사이트를 기준으로 디자인 변경, 기능 추가로 원하는 사이트를 빠르게 제작 가능합니다.\n\n" +
          "- 주요 기능 & 작업:\n" +
          "회원정보 관리(회원 관리, 메일발송 관리)\n" +
          "교육상품 관리(동영상 관리, 강의 카테고리 관리, 강의 관리, 강의 운영관리)\n" +
          "커뮤니티 관리(공지사항, 자주하는 질문, 문의게시판, 수강후기(강의), Q&A(강의)\n" +
          "주문결제 관리(주문 관리, 환불 관리, 주문 통계, 환불 통계, 매출 통계)",
      client: "",
      technologies: "ReactJs, 리덕스, NextJs, vercel, scss, Node.js",
      range: "기획, 서버 구축, 관리자 사이트 프론트엔드 & 백엔드",
      date: "2023.04 ~ 2023.04",
      url: [{
        name: "샘플사이트 고객용",
        link: "https://muracle-demo-lms.vercel.app/",
      }],
      thumbImage: "images/projects/muracle-demo-lms-admin/admin01.png",
      sliderImages: [
        "images/projects/muracle-demo-lms-admin/admin02.png",
        "images/projects/muracle-demo-lms-admin/admin03.png",
        "images/projects/muracle-demo-lms-admin/admin04.png",
      ],
      categories: [filters.ECommerce, filters.Web],
    },
    {
      title: "트립스토어 리뉴얼(Angular ➔ React)",
      projectInfo:
        "기존에 AngularJS로 개발되어 있던 사이트를 ReactJS기반으로 만들면서 PC사이트 디자인과 구성을 변경하는 작업\n\n" +
          "- 주요 기능 & 작업:\n" +
          "기존 코드 분석해서 주요 로직을 다시 깔끔하게 정리하는 작업 필요.\n" +
          "웹 폰트 로드.\n" +
          "검색영역(장소, 날짜, 출발지, 인원 선택, 팝업형태, 최근 검색 영역)\n" +
          "배너 영역(slide 처리, 타임세일 배너(시간)\n" +
          "상품 목록(필터 선택, 정렬)\n" +
          "상품 상세(인피니티 스크롤, 북마크, 최근 검색, 각 상품별로 화면에 보여주는 것 다르게 처리)\n" +
          "path 구조(url 기반으로 처리, 필터들도 URL 기반으로 저장되게 처리, 라우팅처리)\n",
      client: "트립스토어",
      technologies: "ReactJs, Javascript, 리덕스",
      range: "기존 서버 api가 있어서 사이트 클라이언트만 구현",
      date: "2019.09 ~ 2020.01",
      url: {
        name: "https://www.tripstore.kr/",
        link: "https://www.tripstore.kr/",
      },
      thumbImage: "images/projects/tripstore/t1.png",
      sliderImages: [
        "images/projects/tripstore/t2.png",
        "images/projects/tripstore/t3.png",
      ],
      categories: [filters.ECommerce, filters.Web],
    },
    {
      title: "연플(연세대 공동구매) 사이트 & 관리자",
      projectInfo:
        "연세대에서 과/반/동아리 공동구매를 할 수 있는 사이트를 개발\n" +
          "특히 과잠바를 직접 디자인을 선택해서 주문하면 링크가 생성되고\n" +
          "그 링크를 통해 학생들이 결제할 수 있는 기능이 있음.\n\n" +
          "- 주요 기능:\n" +
          "회원가입, 로그인, 소셜로그인\n" +
          "메인 소개 페이지\n" +
          "구매 페이지, 장바구니, 결제\n" +
          "마이페이지\n" +
          "과잠바 공동구매 개설(색상, 디자인, 문구 선택 등..)\n" +
          "공동구매 링크,\n" +
          "결제 내역,\n" +
          "관리자 결제 내역 관리 & 다운로드\n" +
          "관리자 공구방 관리\n" +
          "관리자 고객 내역 관리\n",
      client: "연세대 공식 업체",
      technologies: "ReactJs, php, Laravel, vue.js",
      range: "고객용 페이지 ➔ React기반의 클라이언트 + 백엔드, 관리자용 페이지 ➔ Laravel 기반의 클라이언트 + 백엔드",
      date: "2019.12 ~ 2020.03",
      url: [{
        name: "https://yonple.com/",
        link: "https://yonple.com/",
      }],
      thumbImage: "images/projects/yonple/y1.png",
      sliderImages: [
        "images/projects/yonple/y2.png",
      ],
      categories: [filters.ECommerce, filters.Web],
    },
    {
      title: "변전소 종합예방 진단시스템 & 관리자",
      projectInfo:
        "D3.js를 이용하여 3D/2D 그래프를 이용한 대시보드 성격의 변전소 종합예방 진단 시스템을 개발.\n\n" +
          "- 주요 기능:\n" +
          "로그인 & 회원가입\n" +
          "현재상태 : 지도를 배경으로 여러 기기 심볼를 배치하고 그 심볼의 상태를 확인 할 수 있으며 심볼을 클릭할 경우\n" +
          "실시간 데이터를 이용하여 각 상태를 알맞은 그래프로 보여줌(실시간 3D, 2D차트, gauge 차트 등)\n" +
          "알람 : 알람이 발생하는 내역을 그래프로 확인 하고 이력리스트를 확인함. 각각의 이력을 클릭하면 각 상태에 알맞은 그래프로 팝업으로 보여줌.\n" +
          "종합경향 분석 : 여러가지의 상태를 한꺼번에 보여줄 수 있음. 날짜를 선택하거나 실시간으로도 확인 가능함. 각 심볼을 선택하면 각 상태에 알맞는 그래프를 팝업으로 보여줌.\n" +
          "이벤트 : 이벤트 발생 이력을 확인 가능. 날짜를 선택하거나 실시간으로도 확인 가능함. 각 심볼을 선택하면 각 상태에 알맞는 그래프를 팝업으로 보여줌.\n" +
          "공통 팝업 : Bushing, Cbcm, Dga, Oltc, PddsMini 팝\n" +
          "\n" +
          "관리자\n" +
          "계정 관리\n" +
          "색상&문자 관리\n" +
          "감사이력\n" +
          "센서 관리\n" +
          "진단유닛 관리",
      client: "YPP",
      technologies: "HTML, D3, Javascript",
      range: "서버는 고객사가 진행하고 클라이언트 페이지를 100% 개발함.\nAPI의 스펙을 json으로 미리 구현.\n" +
          "작업후 관리할 수 있도록 설명 문서와 API를 제공함.",
      date: "2021.03 ~ 2021.08",
      url: [{
        name: "로컬 테스트용 사이트",
        link: "https://ypp-private.vercel.app/status.html",
      }],
      thumbImage: "images/projects/ypp/ypp_main.png",
      sliderImages: [
        "images/projects/ypp/ypp4.png",
        "images/projects/ypp/ypp3.png",
        "images/projects/ypp/ypp2_1.png",
        "images/projects/ypp/ypp5.png",
        "images/projects/ypp/ypp6.png",
        "images/projects/ypp/ypp11.png",
      ],
      categories: [filters.Web],
    },
    {
      title: "전시회 앱 패키징 & 오프라인 QR앱 개발",
      projectInfo:
          "1. 전시회 사이트인 '헤레디움' 모바일 사이트 앱 패키징(iOS + Android)\n" +
          "2. 전시회에서 사용되는 QR 체크 앱(iOS + Android)\n" +
          "(iOS 는 아이패드, Android 는 태블릿 기반으로 제작)\n\n" +
          "- 주요 기능 & 작업:\n" +
          "앱 패키징 주요 기능\n" +
          "앱에는 특정 Wifi일때 접속이 가능한 메뉴가 있어 현재 Wifi를 가져와서 웹과 통신해 보내주는 기능\n" +
          "인터넷 연결 되어 있는지 실시간 체크하고 연결 안 되어 있을 때 특정 화면을 보여주는 처리\n" +
          "특정 폰트 로드\n" +
          "권한 요청(내 위치)\n" +
          "하단 네비게이션 구현\n" +
          "세로 화면 고정\n" +
          "웹뷰 구현(다운로드 가능, 새창 처리 등..)\n" +
          "파이어베이스 기반 다이나믹 링크 기능 구현\n" +
          "스토어 배포(iOS, Android)\n" +
          "\n" +
          "QR 앱 주요 기능\n" +
          "API 호출해서 QR 리스트 받아오기\n" +
          "체크 설정 화면(사용할 QR을 체크하고 저장)\n" +
          "Custom 토스트 구현\n" +
          "QR 체크 기능(특정 영역에서만 QR 체크되도록 구현, Blur 처리, 카메라 화면 변경)\n" +
          "QR 체크시 토스트와 사운드 처리(성공/실패)\n" +
          "Database 저장 기능(기기에서 저장)\n" +
          "로그 리스트 화면 구현" +
          "\n" +
          "관리자\n" +
          "계정 관리\n" +
          "색상&문자 관리\n" +
          "감사이력\n" +
          "센서 관리\n" +
          "진단유닛 관리",
      client: "헤레디움",
      technologies: "iOS - SwiftUI, Swift, Combine, AVFoundation\n" +
          "Android - Kotlin, Compose",
      range: "앱 패키징(Android, iOS), QR앱 개발(Android, iOS)",
      date: "2023.01 ~ 2023.03",
      url: [{
        name: "구글 스토어(Android)",
        link: "https://play.google.com/store/apps/details?id=heredium.art.app&hl=ko",
      },
      {
        name: "애플 스토어(iOS)",
        link: "https://apps.apple.com/kr/app/%ED%97%A4%EB%A0%88%EB%94%94%EC%9B%80/id1659816022",
      }],
      thumbImage: "images/projects/heredium/heredium1.jpg",
      sliderImages: [
        "images/projects/heredium/heredium2.jpg",
        "images/projects/heredium/heredium3.jpg",
        "images/projects/heredium/heredium4.jpg",
        "images/projects/heredium/heredium5.jpg",
      ],
      categories: [filters.App],
    },
    {
      title: "기아 360 사이트, 키오스크 개발",
      projectInfo:
          "기아 자동차의 체험 거점인 기아 360을 소개하는 사이트\n" +
          "관리자 사이트에는 체험 거점의 내부 미디어월을 제어하는 기능을 제공\n" +
          "키오스크를 통해 차량 시승을 위한 상담 스케쥴 관리를 제공" +
          "\n\n" +
          "- 주요 기능 & 작업:\n" +
          "사이트 - 정보 제공, 미디어월과 상호작용 체험 컨텐츠 제공\n" +
          "사이트 관리자 - 미디어월 제어/설정\n" +
          "고객용 키오스크 - 자동차 시승 예약\n" +
          "직원용 키오스크 - 자동차 시승 고객 스케쥴링",
      client: "",
      technologies: "Laravel, Vuejs, React, Electron, Firebase",
      range: "디자인을 제외한 모든 프론트엔드, 백엔드 구현(관리자 포함)",
      date: "2021.05. ~ 2021.07.",
      url: [{
        name: "https://kia360.kia.com/",
        link: "https://kia360.kia.com/",
      }],
      thumbImage: "images/projects/kia-360/k1.png",
      sliderImages: [
        "images/projects/kia-360/k2.png",
        "images/projects/kia-360/k3.png",
        "images/projects/kia-360/k4.png",
        "images/projects/kia-360/k5.png",
      ],
      categories: [filters.Web],
    },
    {
      title: "할랄코리아 리뉴얼(지도 API를 이용하여 길찾기 구현)",
      projectInfo:
          "기존 사이트(PHP)는 건드리지 않고 지도부분을 리뉴얼하는 프로젝트.\n" +
          "지역 DB와 레스토랑 DB를 구축하고 지도 API(Konest)를 사용하여 레스토랑 찾기, 길찾기를 구현하고 검색, 상세 페이지를 개발\n\n" +
          "- 주요 기능 & 작업:\n" +
          "1. konest 맵 api 를 사용한 레스토랑 찾기, 길찾기 기능 구현\n" +
          "- 맵 api를 클라이언트에서 직접 호출하면 안되서, 릴레이 서버 구축 필요\n" +
          "- 클라이언트 라이브러리는 leaflet을 사용\n" +
          "2. 지역별 레스토랑 검색 api\n" +
          "3. 레스토랑 상세 api\n" +
          "4. api 서버를 만들기 위해 slim php 프레임웍을 사용.\n" +
          "5. 레스토랑의 좌표 위치를 검색할 수 있는 페이지\n" +
          "6. 레스토랑의 좌표 위치를 엑셀로 DB에 넣을 수 있게 처리\n" +
          "7. 지역 DB 구축\n" +
          "8. 회원 페이지에 쿠폰 리스트 표시, 발급 기능\n" +
          "9. 엑셀로 전달받은 레스토랑 데이터로 DB 구축\n" +
          "10. 관리자에 레스토랑 추가",
      client: "할랄코리아",
      technologies: "PHP, Javascript, Database, MapAPI(Konest)",
      range: "주요 기능 & 작업 참고",
      date: "2020.06. ~ 2020.08.",
      url: [],
      thumbImage: "images/projects/halal/halal1.png",
      sliderImages: [
        "images/projects/halal/halal2.png",
      ],
      categories: [filters.Web],
    },
    {
      title: "오픈 인프라 개발 경진대회 사이트 & 관리자 사이트 개발",
      projectInfo:
          "제 4회 오픈 인프라 개발 경진대회 사이트 & 관리자 사이트 개발\n\n" +
          "- 주요 기능 & 작업:\n" +
          "메인: 대회 과제, 대회 일정, 참가 신청하기\n" +
          "일정 : 참가신청, 서류심사 결과발표, 오리엔테이션, 워크샵\n"+
          "신청 및 확인 : 참가신청 폼 입력 가능하도록 참가 신청 후 접수 확인에서 확인 할 수 있고 수정도 가능하도록 구현\n" +
          "공지사항, Q&A(관리자가 답변 가능)\n" +
          "관리자 사이트 : 공지사항, Q&A관리, 참가신청 관리(목록, 검색, 확인)",
      client: "스페이드 컴퍼니",
      technologies: "PHP, Laravel, Javascript",
      range: "오픈 인프라 개발 경진대회 사이트 프론트엔드 & 백엔드, 관리자사이트 프론트엔드 & 백엔드",
      date: "2022.02. ~ 2022.03.",
      url: [],
      thumbImage: "images/projects/oidc/oidc1.png",
      sliderImages: [
        "images/projects/oidc/oidc2.png",
        "images/projects/oidc/oidc3.png",
        "images/projects/oidc/oidc4.png",
        "images/projects/oidc/oidc5.png",
      ],
      categories: [filters.Web],
    },
    {
      title: "2022년 박물관.미술간 주간 사이트 & 관리자 사이트 개발",
      projectInfo:
          "2022년 박물관.미술간 주간 사이트 & 관리자 사이트 개발\n\n" +
          "- 주요 기능 & 작업:\n" +
          "메인: 메인이미지, 뮤지엄 검색, 이벤트 슬라이드\n" +
          "ABOUT : 행사개요, 추진목적, 제작물 다운로드\n"+
          "VIDEO : 행사 영상 제생\n" +
          "PROGRAM : 각각의 프로그램 소개\n" +
          "MUSEUMS : 박물관, 미술관 찾기(필터 검색, 검색어 입력 검색), API\n" +
          "SOCIAL HUB : 페이스북, 인스타그램 연동\n" +
          "NEWS : 공지사항, 보도자료\n" +
          "관리자 사이트 : 박물관 미술관 관리, 등록, 수정, 프로그램 추가, 수정",
      client: "스페이드 컴퍼니",
      technologies: "PHP, Laravel, Javascript",
      range: "박물관사이트 프론트엔드 & 백엔드, 관리자사이트 프론트엔드 & 백엔드",
      date: "2021.04. ~ 2021.04.",
      url: [],
      thumbImage: "images/projects/museum/mu1.png",
      sliderImages: [
        "images/projects/museum/mu2.png",
        "images/projects/museum/mu3.png",
        "images/projects/museum/mu4.png",
        "images/projects/museum/mu5.png",
      ],
      categories: [filters.Web],
    },
    {
      title: "모바일 앱을 위한 버그 리포팅 툴 앱 & 협업 웹 사이트 제작",
      projectInfo:
          "앱에서 Auto Sync 기능을 사용하여 캡쳐시 자동으로 웹사이트로 사진을 전송시키고\n" +
          "웹에서 프로젝트에 올라간 사진을 이용하여 버그가 발생한 부분에 코멘트를 입력, 멤버에게 할당, 상태 부여할 수 있는 협업 툴 사이트 개발" +
          "- 주요 기능 & 작업:\n\n" +
          "앱\n" +
          "Auto Sync 기능을 켜면 사진을 캡쳐하거나 찍으면 자동으로 설정한 프로젝트로 사진을 업로드하는 기능\n"+
          "이미지를 직접 업로드 하는 기능\n" +
          "이미지를 선택하고 지점을 찍어서 코멘트를 작성하는 기능\n" +
          "이미지를 선택하고 코멘트만 작성하는 기능\n" +
          "코멘트 작성시 멤버 할당 기능, 프로젝트 폴더 선택 기능\n\n" +
          "웹\n" +
          "로그인, 회원가입, 비밀번호 찾기\n" +
          "가입시 설문, 언어 자동 설정\n" +
          "사진 올려서 디자인과 QA앱의 차이 비교\n" +
          "멤버 할당 변경, 답글 기능, 상태 변경 기능\n" +
          "프로젝트 생성, 수정, 이동(드래그 & 드랍)",
      client: "Eppi",
      technologies: "PHP, Laravel, Javascript",
      range: "Android 앱, iOS앱, 웹 클라이언트, 백엔드 개발",
      date: "2021.04. ~ 2021.04.",
      url: [{
        name: "https://effi.io/",
        link: "https://effi.io/",
      }], 
      thumbImage: "images/projects/eppi/eppi1.png",
      sliderImages: [
        "images/projects/eppi/eppi2.png",
        "images/projects/eppi/eppi3.png",
        "images/projects/eppi/eppi4.png",
        "images/projects/eppi/eppi5.png",
      ],
      categories: [filters.App, filters.Web],
    },
    {
      title: "스피쿠스 안드로이드 앱 유지보수 관리",
      projectInfo:
          "기존 개발되어 있는 앱의 유지보수\n" +
          "매월 초에 수정을 원하거나 기능을 추가하고 싶은 것들을 전달 받아\n" +
          "기존 앱을 수정 배포함.",
      client: "스피쿠스",
      technologies: "Android, JAVA",
      range: "Android 앱 유지보스",
      date: "2019.09. ~ 2020.03.",
      url: [{
        name: "구글 스토어(Android)",
        link: "https://play.google.com/store/apps/details?id=com.spicus&hl=ko",
      }], 
      thumbImage: "images/projects/spicus/spicus1.png",
      sliderImages: [
        "images/projects/spicus/spicus2.png",
        "images/projects/spicus/spicus3.png",
      ],
      categories: [filters.App],
    },
    // {
    //   title: "현대자동차 앱 프로토타입(시연용) 앱",
    //   projectInfo:
    //       "현대자동차 앱 프로토타입(시연용) 앱을 제작함.\n" +
    //       "여러 페이지들의 뷰를 제작하고 인터렉티브(카운터, 입력된 데이터를 보여주는 처리 등..)를 구현." +
    //       "애니메이션 처리(지도, 팝업, 영상, 페이드인효과 등..)를 구현.\n\n" +
    //       "- 주요 기능:\n" +
    //       "메인, Mobility(리스트, 팝업 제작, 검색 리스트), Mobility Route Search, Detail Route(경로, tab, 데이터 연결, 지도 이미지)\n" +
    //       "Reservation for Business Trip(카운터 컴포넌트 제작, 인터렉티브 구현)\n" +
    //       "UAM List, Family Trip(option 선택 구현, 인터렉티브 구현, 레이아웃 제작, 데이터 연결, 리스트),\n" +
    //       "UAM List(Quick Service탭), Happy Service, Detail Address, on Delevery, Emergency Information, Medical UAM\n" +
    //       "기타 팝업들 구현,\n" +
    //       "애니메이션 구현",
    //   client: "현대자동차",
    //   technologies: "Android, JAVA, Kotlin",
    //   range: "현대자동차 앱 프로토타입(시연용) 앱을 제작",
    //   date: "2021.06. ~ 2021.07.",
    //   url: [],
    //   thumbImage: "images/projects/hyundai-demo-app/h1.png",
    //   sliderImages: [
    //     "images/projects/hyundai-demo-app/h2.png",
    //     "images/projects/hyundai-demo-app/h3.png",
    //     "images/projects/hyundai-demo-app/h4.png",
    //     "images/projects/hyundai-demo-app/h5.png",
    //     "images/projects/hyundai-demo-app/h6.png",
    //   ],
    //   categories: [filters.App],
    // },
    {
      title: "오월평화페스티벌 사이트 제작",
      projectInfo:
          "오월평화페스티벌 사이트 프론트엔드, 백엔드 구현\n" +
          "\n" +
          "- 주요 기능 & 작업:\n" +
          "메인페이지 : 518 TV, ebook 다운로드, 오늘의 518\n" +
          "오월평화페스티벌 : 행사소개\n" +
          "518PEOPLE\n" +
          "518WORLDWIDE\n" +
          "518CULTURE,\n" +
          "소통마당",
      client: "스페이드컴퍼니",
      technologies: "PHP, Laravel, Javascript, Vue.js",
      range: "오월평화페스티벌 사이트 프론트엔드, 백엔드 구현",
      date: "2020.03. ~ 2020.04.",
      url: [{
        name: "http://www.518seoulspring.org",
        link: "http://www.518seoulspring.org/",
      }], 
      thumbImage: "images/projects/518/518.jpg",
      sliderImages: [
        "images/projects/518/518_1.png",
        "images/projects/518/518_2.png",
        "images/projects/518/518_3.png",
        "images/projects/518/518_4.png",
      ],
      categories: [filters.Web],
    },
    {
      title: "스크롤 기반 애니메이션 사이트 구현",
      projectInfo:
          "스크롤 기반 애니메이션 사이트 구현(STARTNET)\n" +
          "Startnet(https://startnetcompany.cafe24.com/)\n\n" +
          "- 주요 기능 & 작업:\n" +
          "모바일용과 pc용 따로 구현\n" +
          "스크롤시 정해진 애니메이션대로 작동하는(마치 ppt슬라이드처럼) 사이트를 개발\n"+
          "gsap 애니메이션 라이브러리를 이용해서 구현함.\n"+
          "모든 애니메이션을 함수 호출로 구현되어 특정 부분부터 실행이 가능하게 구현(메뉴 클릭으로도 가능)",
      client: "STARTNET",
      technologies: "Javascript, html5, gsap",
      range: "서버가 없는 사이트. 사이트의 프론트엔드(모바일용, PC용)를 구현함",
      date: "2020.04. ~ 2020.04.",
      url: [{
        name: "https://startnetcompany.cafe24.com",
        link: "https://startnetcompany.cafe24.com/",
      }], 
      thumbImage: "images/projects/startnet/startnet1.png",
      sliderImages: [
        "images/projects/startnet/startnet2.png",
        "images/projects/startnet/startnet3.png",
        "images/projects/startnet/startnet4.png",
        "images/projects/startnet/startnet6.png",
        "images/projects/startnet/startnet9.png",
        "images/projects/startnet/startnet_mobile1.png",
        "images/projects/startnet/startnet_mobile3.png",
        "images/projects/startnet/startnet_mobile5.png",
        "images/projects/startnet/startnet_mobile7.png",
        "images/projects/startnet/startnet_mobile12.png",
        "images/projects/startnet/startnet_mobile13.png",
      ],
      categories: [filters.Web],
    },
    {
      title: "스크롤 기반 애니메이션 사이트 구현",
      projectInfo:
          "스크롤 기반의 애니메이션이 작동하는 사이트(SLAB 사이트)를 제작함.",
      client: "SLAB",
      technologies: "HTML5, Javascript",
      range: "서버가 없는 사이트. 사이트의 프론트엔드(모바일용, PC용)를 구현함",
      date: "2020.10. ~ 2020.11.",
      url: [{
        name: "https://slab-blond.vercel.app",
        link: "https://slab-blond.vercel.app/",
      }], 
      thumbImage: "images/projects/slab/slab1.png",
      sliderImages: [
        "images/projects/slab/slab2.png",
        "images/projects/slab/slab3.png",
      ],
      categories: [filters.Web],
    },
    {
      title: "Parallax Scrolling(패럴랙스 스크롤링) 사이트 구현",
      projectInfo:
          "Parallax Scrolling(패럴랙스 스크롤링) 사이트(Tangram 사이트)를 제작.\n"+
          "반응형으로 사이트가 커지고 작아져도 이상없이 작동하도록 구현.\n"+
          "모바일에서도 가로모드에서 잘 보이도록 작업함",
      client: "Tangram",
      technologies: "HTML, Javascript",
      range: "서버가 없는 사이트. 사이트의 프론트엔드(모바일용, PC용)를 구현함",
      date: "2015.02. ~ 2015.03.",
      url: [{
        name: "https://parallax-tangram.vercel.app",
        link: "https://parallax-tangram.vercel.app/",
      }], 
      thumbImage: "images/projects/tangram/tangram_main.png",
      sliderImages: [
        "images/projects/tangram/tangram2.png",
        "images/projects/tangram/tangram3.png",
        "images/projects/tangram/tangram4.png",
        "images/projects/tangram/tangram5.png",
        "images/projects/tangram/tangram_mobile1.png",
        "images/projects/tangram/tangram_mobile2.png",
        "images/projects/tangram/tangram_mobile3.png",
        "images/projects/tangram/tangram_mobile4.png",
      ],
      categories: [filters.Web],
    },
    {
      title: "ETF(주식) & 포트폴리오 관리 사이트(2018 개인프로젝트)",
      projectInfo:
          "회사 다닐 때 개인적으로 취미삼아 만들어 봤었던 사이트입니다.\n" +
          "주식 데이터는 KRX API를 이용하여 5분마다 가져오도록 처리(지금은 중단됨)\n" +
          "네이버 소셜로그인을 이용해 간편하게 가입 가능\n" +
          "자신이 가진 ETF나 주식을 증권사에서 제공하는 엑셀파일과 복사 붙여넣기로 쉽게 입력 가능\n" +
          "종목을 자신의 매매내역과 함께 볼 수 있게 하고 차트로 볼 수 있게 처리(나의 평균 매수가 표시)\n" +
          "일자별 자산에서 매매기록을 볼 수 있도록 처리, 필터 정렬 기능\n" +
          "분류별 보기 기능(차트, 비중 차트)\n" +
          "메모 추가 기능, 메모를 입력한 페이지를 바로 확인 가능",
      client: "",
      technologies: "javascript, php",
      range: "사이트의 기획, 디자인, 개발(프론트엔드, 백엔드)",
      date: "2018.09. ~ 2018.11.",
      url: [{
        name: "https://hamstock.cafe24.com",
        link: "https://hamstock.cafe24.com/",
      }], 
      thumbImage: "images/projects/hamstock/hamstock_main.png",
      sliderImages: [
        "images/projects/hamstock/hamstock_2.png",
        "images/projects/hamstock/hamstock_3_1.png",
        "images/projects/hamstock/hamstock_4.png",
        "images/projects/hamstock/hamstock_5.png",
        "images/projects/hamstock/hamstock_6.png",
        "images/projects/hamstock/hamstock_7.png",
        "images/projects/hamstock/hamstock_8.png",
        "images/projects/hamstock/hamstock_9.png",
        "images/projects/hamstock/hamstock_10.png",
        "images/projects/hamstock/hamstock_11.png",
        "images/projects/hamstock/hamstock_12.png",
        "images/projects/hamstock/hamstock_13.png",
      ],
      video: `https://serviceapi.nmv.naver.com/flash/convertIframeTag.nhn?vid=F0AEABFFD604AB9FAD18BA2ADD23A81E3BC3&outKey=V125ca44048ffa4acab530ca5802f700e6a540cd993f0823a07190ca5802f700e6a54`,
      categories: [filters.Web],
    },
    {
      title: "전자부품 매입전문 사이트 & 관리자 제작",
      projectInfo:
          "전자부품 매입전문 JST 사이트 개발\n" +
          "관리자용 로그인, 게시판 관리 구현",
      client: "JST",
      technologies: "PHP, laravel, Javascript, Vue.js",
      range: "전자부품 매입전문 JST 사이트 프론트엔드, 백엔드 구현, 관리자용 게시판 관리 구현",
      date: "2021.05. ~ 2021.05.",
      url: [{
        name: "http://jst5024.com",
        link: "http://jst5024.com/",
      }], 
      thumbImage: "images/projects/jst/jst_main.png",
      sliderImages: [
        "images/projects/jst/jst_2.png",
        "images/projects/jst/jst_3.png",
        "images/projects/jst/jst_4.png",
        "images/projects/jst/jst_5.png",
        "images/projects/jst/jst_6.png",
      ],
      categories: [filters.Web],
    },
    {
      title: "Dooray미팅 리뉴얼(퍼블리싱, 반응형)",
      projectInfo:
          "Dooray 사이트의 퍼블리싱 작업(반응형)",
      client: "Dooray",
      technologies: "Publishing",
      range: "퍼블리싱 ",
      date: "2020.09. ~ 2020.10.",
      url: [{
        name: "https://dooray.com",
        link: "https://dooray.com",
      }], 
      thumbImage: "images/projects/dooray/dooray1.png",
      sliderImages: [
        "images/projects/dooray/dooray2.png",
      ],
      categories: [filters.Publishing],
    },
    {
      title: "Stay1 사이트 퍼블리싱 작업",
      projectInfo:
          "청년임대주택 Stay1 사이트의 퍼블리싱 작업+ 애니메이션 작업 의뢰(반응형)\n" +
          "\n" +
          "- 사이트 전체의 퍼블리싱\n" +
          "- 메인페이지의 애니메이션(JQuery)\n" +
          "- 버튼 등의 인터렉티브 구현",
      client: "Stay1",
      technologies: "Publishing",
      range: "퍼블리싱 ",
      date: "2020.05. ~ 2020.05.",
      url: [], 
      thumbImage: "images/projects/stay1/stay1.png",
      sliderImages: [
        "images/projects/stay1/stay2.png",
      ],
      categories: [filters.Publishing],
    },
      ,
      ,
      ,
];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              Muracle Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                <span>ALL</span>
                <span style={{fontSize:13}}>{` (${projectsData.length})`}</span>

              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  <span>{filters[oneKey]}</span>
                  <span style={{fontSize:13}}>{` (${projectsData.filter((item)=>item.categories.join(",").indexOf(filters[oneKey]) > -1).length})`}</span>
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-3 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">{project.categories.join(", ")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
