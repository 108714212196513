import React from "react";
import Reveal from "react-awesome-reveal";
import {fadeInUpShorter} from "../utils/keyframes";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Graphic Design",
      desc: "아이콘, 이미지 등 필요한 구성요소를 직접 디자인합니다.",
      icon: "fas fa-palette",
    },
    {
      name: "Web Responsive Design & Develop",
      desc: "모바일 웹과 PC 웹 등 다양한 플랫폼에 최적화 된 디자인을 구현하며(디자인은 Figma 파일로 제공), 반응형 디자인에 맞게 디자인하고 개발합니다.",
      icon: "fas fa-desktop",
    },
    {
      name: "UI/UX Design & Planning",
      desc: "사용자 경험과 인터페이스를 고려하여 기획과 디자인을 합니다.",
      icon: "fas fa-pencil-ruler",
    },
    {
      name: "App Design & Develop",
      desc: "iOS와 Android에 맞는 디자인을 Figma 파일로 제공하며, Native 모바일 앱을 개발합니다.",
      icon: "fas fa-paint-brush",
    },
    {
      name: "Business Analysis & Share Progress ",
      desc: "고객의 요구사항을 꼼꼼하게 정리하고 작성하고 개발 과정을 투명하게 공유합니다.",
      icon: "fas fa-chart-area",
    },
    {
      name: "SEO Marketing",
      desc: "검색 엔진에 최적화하여 마케팅 효과를 극대화합니다.",
      icon: "fas fa-bullhorn",
    },
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What We Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                    <Reveal className="col-md-6" key={index} keyframes={ fadeInUpShorter } delay={ 200*index } duration={ 800 }>
                      <div className="featured-box style-3 mb-5">
                        <div
                          className={
                            "featured-box-icon text-primary  shadow-sm rounded " +
                            (darkTheme ? "bg-dark-1" : "bg-white")
                          }
                        >
                          <i className={service.icon} />
                        </div>
                        <h3 className={darkTheme ? "text-white" : ""}>
                          {service.name}
                        </h3>
                        <p
                          className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                        >
                          {service.desc}
                        </p>
                      </div>
                  </Reveal>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
